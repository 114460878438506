<template>
  <span>
    <b-row>
      <b-col>
        <h1>View</h1>
      </b-col>
      <b-col class="text-right">
        <b-button
          style="margin-bottom: 7px"
          @click="$router.push('/pages/dev-tools/checklists')"
        >
          Back
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="survey">
      <b-col>
        <b-card>
          <survey :survey="survey" />
        </b-card>
      </b-col>
    </b-row>
  </span>
</template>
<script>
import checklistApi from '@api/checklist_template_headers';
import { Model } from 'survey-core';

export default {
  name: 'ChecklistView',
  data: () => ({
    formData: null,
    survey: new Model(),
  }),
  mounted() {
    if (+this.$route.query.id) {
      this.loadChecklist();
    }

    this.survey = new Model();
  },
  methods: {
    loadSurvey() {
      const survey = new Model(json);
      survey.onComplete.add((sender, options) => {
        console.log(JSON.stringify(sender.data, null, 3));
      });

      this.survey = survey;
    },
    loadChecklist() {
      checklistApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          this.formData = data;
          var defaultJSON = {
            ...data,
            pages: [],
          };

          if (data.details && data.details.data) {
            const details = data.details.data;

            details.forEach((detail) => {
              detail.elements = [];
              if (detail.checklists && detail.checklists.data) {
                const checklists = detail.checklists.data;

                checklists.forEach((checklist) => {
                  delete checklist.type;
                  checklist.type = checklist.response_type;
                  checklist.name = checklist.title;
                  checklist.visible = true;

                  if (checklist.response_type_options) {
                    checklist.choices = JSON.parse(
                      checklist.response_type_options
                    );
                  }

                  detail.elements.push(checklist);
                });
              }

              delete detail.type;
              detail.visible = true;
              defaultJSON.pages.push(detail);
            });
          }

          const survey = new Model({
            pages: defaultJSON.pages,
            showPreviewBeforeComplete: 'showAnsweredQuestions',
          });

          survey.onComplete.add((sender, options) => {
            console.log(JSON.stringify(sender.data, null, 2));
          });

          this.survey = survey;
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style>
[dir] .sd-container-modern {
  margin-bottom: calc(10 * var(--base-unit, 0px)) !important;
}
</style>
